<template>
<div class="auth-box">
    <div class="tgju-widget-content full-padding overflow-hidden">
        <LoginRegister />
    </div>
</div>
</template>

<style lang="scss" scoped>
form i {
    margin-left: 5px;
}
</style>

<script>
// وضعیت کنونی این ویو : غیرفعال
// استفاده از کامپوننت خارجی auth.js
import LoginRegister from '@/components/LoginRegister.vue'

export default {
    name: 'LoginView',
    components: {
        LoginRegister,
    },
    data: function () { return {} },
    mounted() {
        //ریداریکت کردن کاربر به پروفایل در صووتی کاربر لاگین کرده بود و همزمان درخواست باز کردن صفحه لاگین رو داده باشد . 
        if (Vue.prototype.$helpers.userAuth() || localStorage.getItem('user_data')) {
            window.location = '/profile';
        }
    },
}
</script>
